import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = ({
  pageContext: {
    data: { title, content, colophon, ...data }
  }
}) => {
  const date = new Date(data.date * 1000)
  return (
    <Layout>
      <SEO title={title} />

      <article id="content">
        <section id="main">
          <h1>{title}</h1>

          <dl className="metadata">
            <dt>Date</dt>
            <dd data-item="date">
              <time dateTime={JSON.stringify(date)}>
                {JSON.stringify(date)}
              </time>
            </dd>

            {/* <dt data-item="material">Material</dt>
            <dd data-item="material">Fotomontage</dd> */}
          </dl>

          <div dangerouslySetInnerHTML={{ __html: content }} />

          <small id="colophon">
            <div dangerouslySetInnerHTML={{ __html: colophon }} />
          </small>
        </section>

        {/* <aside className="category-nav-top">
          <h3>Other Projects</h3>
          <nav className="page-navigation">
            <p>
              <a href="../../works/cafe-klein-arabistan/">
                Café „Klein Arabistan“
              </a>
            </p>

            <p id="project-count">1/6</p>

            <p>
              <a href="" />
            </p>
          </nav>
        </aside> */}

        {/* <section id="media">
          <h3>Media</h3>

          <nav id="gallery" className="js-only">
            <p id="gallery-count">
              <em>№</em> <span>1/1</span>
            </p>
            <p>
              <a href="#" id="next-image">
                Next image
              </a>
            </p>
            <p>
              <a href="#" id="previous-image">
                Previous image
              </a>
            </p>
          </nav>
          <div id="media">
            <figure>
              <img
                src="../../content/1.works/1.insert-coin/tumblr_ksevkp2oEr1qa7757o1_1280-1024x680.jpg"
                alt=""
                width="1024"
                height="680"
              />
            </figure>
          </div>
        </section> */}

        {/* <aside className="category-nav-bottom">
          <h3>Other Projects</h3>
          <nav className="page-navigation">
            <p>
              <a href="../../works/cafe-klein-arabistan/">
                Café „Klein Arabistan“
              </a>
            </p>

            <p id="project-count">1/6</p>

            <p>
              <a href="" />
            </p>
          </nav>
        </aside> */}
      </article>
    </Layout>
  )
}
export default IndexPage
