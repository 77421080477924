import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Helmet from "react-helmet"

const inlineHeadStyle = `
.js-only, .sr-only {
display: none;
}
body.js-enabled .js-only{
display: inherit;
}
nav#gallery {
display: none;
}
`

const Header = ({ siteTitle }) => (
  <>
    <Helmet>
      <style type="text/css" media="screen">
        {inlineHeadStyle}
      </style>
    </Helmet>
    <header>
      <hgroup>
        <h1>
          <Link to="/">Max F. Albrecht</Link>{" "}
          <small className="tagline">damn’ fine arts</small>{" "}
        </h1>
        <small className="contact">
          <p>
            <a href="mailto:1@178.is">1@178.is</a>
          </p>
        </small>{" "}
      </hgroup>
    </header>
  </>
)

Header.propTypes = {
  siteTitle: PropTypes.string
}

Header.defaultProps = {
  siteTitle: ``
}

export default Header
